
import { defineComponent } from 'vue'
import HelloEchart from '../components/HelloEchart.vue'

export default defineComponent({
  components: {
    HelloEchart
  },
  setup() {
    return {}
  }
})
