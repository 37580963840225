
import { defineComponent, onMounted } from 'vue'
import useECharts from '../composable/useECharts'

export default defineComponent({
  setup() {
    const { echartsdiv, draw } = useECharts()

    onMounted(() => {
      draw()
    })

    return {
      echartsdiv
    }
  }
})
