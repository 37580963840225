<template>
  <div id="main" style="width: 600px;height:400px;" ref="echartsdiv"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import useECharts from '../composable/useECharts'

export default defineComponent({
  setup() {
    const { echartsdiv, draw } = useECharts()

    onMounted(() => {
      draw()
    })

    return {
      echartsdiv
    }
  }
})
</script>

<style scoped></style>
