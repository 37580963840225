import { ref } from 'vue'
import * as echarts from 'echarts/dist/echarts.js'

const useECarts = legendList => {
  const chartElement = ref(null)

  const draw = () => {
    const chartInstanse = echarts.init(chartElement.value)

    // 顏色
    const GRAY_500 = '#797E89'
    const GRAY_50 = '#E4E6EC'

    const BLUE = '#355DFF' // 系統事件
    const GREEN = '#3DCB97' // 管理日誌
    const YELLOW = '#FCCB36' // 異常事件
    const VIOLET = '#B7C0EB' // 主機事件
    const RED = '#E85153' // 封鎖事件
    const PURPLE = '#6C5DD3' // 授權事件

    const option = {
      backgroundColor: 'transparent',
      legend: {
        show: false,
        left: '0%',
        bottom: '0%',
        icon: 'rect',
        textStyle: {
          color: '#FFFFFF'
        },
        selected: {}
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          z: 0,
          lineStyle: {
            color: GRAY_50,
            type: 'solid'
          }
        }
      },
      grid: {
        top: '1%',
        right: '5%',
        bottom: '15%',
        left: '5%',
        show: true,
        borderColor: 'rgba(255,255,255, 0.05)'
      },
      xAxis: [
        {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: GRAY_50,
              opacity: 0.1,
              width: 1
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: GRAY_500,
            width: 100,
            lineHeight: 18,
            formatter: (value, index) => {
              if (index == 0 || value.split(' ')[0] === '00:00') {
                return value.split(' ')[0] + '\n' + value.split(' ')[1]
              }
              return value.split(' ')[0]
            }
          },
          splitLine: {
            show: true,
            interval: 1,
            lineStyle: {
              color: GRAY_50,
              opacity: 0.1,
              width: 1
            }
          },
          boundaryGap: false,
          data: [
            '12:00 01-01',
            '14:00 01-01',
            '16:00 01-01',
            '18:00 01-01',
            '20:00 01-01',
            '22:00 01-01',
            '00:00 01-02',
            '02:00 01-02',
            '04:00 01-02',
            '06:00 01-02',
            '08:00 01-02',
            '10:00 01-02',
            '12:00 01-02'
          ]
        }
      ],
      yAxis: [
        {
          show: false,
          type: 'value',
          min: 0
        }
      ],
      series: [
        {
          name: '系統事件',
          type: 'line',
          showSymbol: false,
          showAllSymbol: true,
          symbol: 'circle',
          symbolSize: 11,
          lineStyle: {
            color: BLUE,
            width: 1,
            opacity: 0.8
          },
          label: {
            show: false
          },
          itemStyle: {
            color: BLUE,
            borderColor: GRAY_50,
            borderWidth: 2
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(53, 93, 255, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(53, 93, 255, 0)'
                  }
                ],
                false
              )
            }
          },
          data: [14, 14, 10, 9, 6, 7, 10, 10, 10, 11, 9, 5, 17]
        },
        {
          name: '管理日誌',
          type: 'line',
          showSymbol: false,
          showAllSymbol: true,
          symbol: 'circle',
          symbolSize: 11,
          lineStyle: {
            color: GREEN,
            width: 1,
            opacity: 0.8
          },
          label: {
            show: false
          },
          itemStyle: {
            color: GREEN,
            borderColor: GRAY_50,
            borderWidth: 2
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(61, 203, 151, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(61, 203, 151, 0)'
                  }
                ],
                false
              )
            }
          },
          data: [19, 18, 15, 20, 20, 22, 18, 12, 12, 16, 18, 10, 8]
        },
        {
          name: '異常事件',
          type: 'line',
          showSymbol: false,
          showAllSymbol: true,
          symbol: 'circle',
          symbolSize: 11,
          lineStyle: {
            color: YELLOW,
            width: 1,
            opacity: 0.8
          },
          label: {
            show: false
          },
          itemStyle: {
            color: YELLOW,
            borderColor: GRAY_50,
            borderWidth: 2
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(252, 203, 54, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(252, 203, 54, 0)'
                  }
                ],
                false
              )
            }
          },
          data: [0, 0, 0, 0, 4, 1, 0, 1, 1, 2, 0, 0, 0]
        },
        {
          name: '主機事件',
          type: 'line',
          showSymbol: false,
          showAllSymbol: true,
          symbol: 'circle',
          symbolSize: 11,
          lineStyle: {
            color: VIOLET,
            width: 1,
            opacity: 0.8
          },
          label: {
            show: false
          },
          itemStyle: {
            color: VIOLET,
            borderColor: GRAY_50,
            borderWidth: 2
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(183, 192, 235, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(183, 192, 235, 0)'
                  }
                ],
                false
              )
            }
          },
          data: [30, 28, 27, 29, 32, 26, 26, 25, 18, 30, 33, 34, 30]
        },
        {
          name: '封鎖事件',
          type: 'line',
          showSymbol: false,
          showAllSymbol: true,
          symbol: 'circle',
          symbolSize: 11,
          lineStyle: {
            color: RED,
            width: 1,
            opacity: 0.8
          },
          label: {
            show: false
          },
          itemStyle: {
            color: RED,
            borderColor: GRAY_50,
            borderWidth: 2
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(232, 81, 83, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(232, 81, 83, 0)'
                  }
                ],
                false
              )
            }
          },
          data: [3, 5, 4, 2, 1, 7, 6, 3, 5, 4, 2, 1, 7]
        },
        {
          name: '授權事件',
          type: 'line',
          showSymbol: false,
          showAllSymbol: true,
          symbol: 'circle',
          symbolSize: 11,
          lineStyle: {
            color: PURPLE,
            width: 1,
            opacity: 0.8
          },
          label: {
            show: false
          },
          itemStyle: {
            color: PURPLE,
            borderColor: GRAY_50,
            borderWidth: 2
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(108, 93, 211, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(108, 93, 211, 0)'
                  }
                ],
                false
              )
            }
          },
          data: [13, 13, 11, 12, 7, 8, 9, 7, 9, 9, 6, 13, 17]
        }
      ]
    }

    // 根據傳入的 legendList 設定初始顯示狀態
    legendList.forEach(legend => {
      option.legend.selected[legend.name] = legend.checked
    })

    // 使用剛指定的配置和數據顯示圖表
    chartInstanse.setOption(option)

    return chartInstanse
  }

  return {
    chartElement,
    draw
  }
}

export default useECarts
